<template>
  <Contracts :page="'edit'" />
</template>

<script>
import Contracts from '../../../Shared/Pages/Contracts/index.vue'

export default {
  components: {
    Contracts
  }
}
</script>
